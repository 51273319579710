//
//
//  Team Context
//
//

import {Team} from "../interfaces.ts"
import {createContext, ReactNode, useContext, useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react";
import {Anchor, Button, Flex, LoadingOverlay, Title, Text} from "@mantine/core";
import {API} from "../api.ts";
import useAdmin from "../hooks/admin.ts";
import SwitchTeamsModal from "../components/SwitchTeamsModal.tsx";
import {useTranslate} from "@tolgee/react";

interface TeamContextInterface {
    team: Team
    setTeam: (team: Team) => void
}

const TeamContext = createContext<TeamContextInterface | undefined>(undefined)

export const TeamProvider = function ({children}: { children: ReactNode }) {
    const isAdmin = useAdmin()
    const {logout} = useAuth0()
    const {t} = useTranslate()
    const [noTeams, setNoTeams] = useState(false)
    const {getAccessTokenSilently} = useAuth0()
    const [loading, setLoading] = useState(true)
    const [currentTeam, setCurrentTeam] = useState<Team>()

    function doLogout() {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        }).catch(console.error)
    }

    useEffect(() => {
        const teamId = localStorage.getItem("teamId")

        if (teamId == null) {
            sessionStorage.removeItem("dashboard-knowledge-base-selected-assistant")

            // If admin, don't select any team
            if (isAdmin) {
                setLoading(false)
                return
            }

            API.getUserTeams(getAccessTokenSilently)
                .then((teams: any) => {
                    if (teams["items"].length === 0) {
                        setNoTeams(true)
                    } else {
                        // Select the first team
                        setCurrentTeam(teams["items"][0])
                        localStorage.setItem("teamId", teams["items"][0].id.toString())
                    }
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            API.getTeam(getAccessTokenSilently, teamId)
                .then((team: Team) => {
                    setCurrentTeam(team)
                }).catch(error => {
                    localStorage.removeItem("teamId")
                    console.error(error)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }, [getAccessTokenSilently, isAdmin])

    useEffect(() => {
        if (currentTeam != null) {
            localStorage.setItem("teamId", currentTeam.id.toString())
        }
    }, [currentTeam?.id])

    if (loading) {
        return <LoadingOverlay visible loaderProps={{size: "xl", variant: "oval"}}/>
    }

    if (noTeams) {
        return (
            <Flex style={{minHeight: "100vh"}} align="center" justify="center" direction="column" gap={16}>
                <Title order={2}>{t("team.no-teams", "No teams available")}</Title>
                <Text c="dimmed">
                    {t("team.contact", "Please contact")} <Anchor href="mailto:miguel.castanedo@majorel.com?subject=%5BMAIA%20Knowledge%20Base%5D%20No%20team%20available">Miguel Castanedo</Anchor> {t("team.contact-error", "if you think this is an error.")}
                </Text>
                <Button onClick={doLogout}>{t("team.logout", "Logout")}</Button>
            </Flex>
        )
    }

    if (currentTeam == null) {
        return (
            <SwitchTeamsModal
                opened={true}
                onSelect={(team) => {
                    setLoading(false)
                    setNoTeams(false)
                    setCurrentTeam(team)
                    localStorage.setItem("teamId", team.id.toString())
                }}
                withCloseButton={false}
                onClose={() => void 0}
            />
        )
    }

    return (
        <TeamContext.Provider value={{team: currentTeam, setTeam: setCurrentTeam}}>
            {children}
        </TeamContext.Provider>
    )
}

export function useTeam() {
    const teamContext = useContext(TeamContext)
    if (!teamContext) {
        throw new Error("No TeamContext.Provider found when calling useTeam.")
    }
    return teamContext
}
